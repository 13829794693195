














































































import { PageBase } from "@/core/models/shared";
import { CashBackService } from "@/core/services/geral";
import { Component } from "vue-property-decorator";

@Component
export default class RelatorioChamada extends PageBase{
    service = new CashBackService();
    lista: any[] = [];
    filtro = {
        turmaId: 0,
        alunoId: 0
    }
    overlay: boolean = false;
    
    mounted() {

        this.filtro.turmaId = Number(this.$route.query.turmaId);
        this.filtro.alunoId = Number(this.$route.query.alunoId);

        this.overlay = true;

        this.service.Relatorio(this.filtro.turmaId, this.filtro.alunoId).then(
            res =>{
                this.lista = res.data;
            },
            err => {
                if (!err.response){
                    this.$swal("Aviso", "Não foi possível acessar a API", "error");
                }
                else{
                    this.$swal("Aviso",err.response.data,"error")
                }
            }
        ).finally(() => {
            this.overlay = false;
        })
    }
}
